@import "../../config/constant.scss";

$size: 'bs';

@mixin btn($size) {
    border-radius: 2px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 17px; 
    align-items: center;
    cursor: pointer;
    @if $size == 'sm' {
        width: 110px;
        height: 40px;
    } @else if $size == 'smd' {
        width: 200px;
        height: 40px;
    } @else if $size == 'md' {
        width: 200px;
        height: 50px;
    } @else if $size == 'lg' {
        width: 300px;
        height: 50px;
    } @else if $size == 'lg-fs-md' {
        width: 300px;
        height: 50px;
        font-size: 12px;
    } @else if $size == 'sm-fs'{
        width: 100%;
        height: 40px;
    } @else {
        width: 100%;
        height: 50px;
    }
}

@mixin btn-rb($bg-color, $bd-color, $font-color) {
    background-color: $bg-color;
    border: 1px solid $bd-color;
    color: $font-color;    
    text-transform: uppercase;
    &:hover { color: $font-color; }
    &:disabled {
        filter: saturate(50%)!important;
        cursor: default;
    }
}

.btn-rb-dark-blue{
    @include btn-rb($rb-dark-blue, $rb-blue-border, $rb-white)
}

.btn-rb-blue{
    @include btn-rb($rb-blue, $rb-blue-border, $rb-white)
}

.btn-rb-white{
    @include btn-rb($rb-white, $primary, $primary)
}

.btn-rb-white-rounded{
    @include btn-rb($rb-white, $secondary, $secondary);
    border-radius: 25px !important;
}

.btn-rb-white-secondary{
    @include btn-rb($rb-white, $secondary, $secondary)
}

.btn-rb-grey{
    @include btn-rb($rb-white, $rb-light-grey, $rb-dark-grey)
}

.btn-rb-channel-partner{
    background-color: $rb-white;
    border: 1px solid $rb-light-grey;
    color: $rb-black;
    padding-left: 15px;
    text-align: left;
    &:hover, &:disabled {
        background-color: $rb-light-grey;
    }
    &:focus {
        outline: none;
    }
}

.btn-rb-yellow{
    @include btn-rb($secondary, $secondary, $rb-white)
}

.btn-rb-primary{
    @include btn-rb($primary, $primary, $rb-white)
}

.btn-rb-secondary{
    @include btn-rb($secondary, $secondary, $rb-white)
}

.btn-rb-secondary-rounded{
    @include btn-rb($secondary, $rb-white, $rb-white);
    border-radius: 25px !important;
}

.btn-rb-tertiary{
    @include btn-rb($tertiary, $rb-white, $rb-white)
}

.btn-rb-fsr{
    @include btn-rb($rb-fsr, $rb-fsr, $rb-white)
}

.btn-rb-yellow-outline{
    @include btn-rb($rb-white, $rb-yellow, $rb-yellow)
}

.btn-rb-fsr-outline{
    @include btn-rb($rb-white, $rb-fsr, $rb-fsr)
}

.btn-rb-landing-page {
    @include btn-rb($primary, $primary, $rb-white)
}

.btn-rb-landing-page-rounded {
    @include btn-rb($primary, $primary, $rb-white);
    border-radius: 25px !important;
}

.btn-rb-transparent-rounded {
    @include btn-rb(transparent, $primary, $primary);
    border-radius: 25px !important;
    border: solid 2px $primary !important;
    font-weight: 600;
}

.btn-bs{
    @include btn('bs')
}

.btn-sm{
    @include btn('sm')
}

.btn-sm-fs{
    @include btn('sm-fs')
}

.btn-smd{
    @include btn('smd')
}

.btn-md{
    @include btn('md')
}

.btn-lg{
    @include btn('lg')
}
.btn-lg-fs-md{
    @include btn('lg-fs-md')
}

.rb-checkbox.custom-control {
    padding-left: 30px
}
.rb-checkbox.custom-checkbox .custom-control-label::before, 
.rb-checkbox.custom-checkbox .custom-control-label::after {
    left: -30px;
    width: 24px;
    height: 24px;
}

.rb-checkbox.custom-checkbox .custom-control-label {
    line-height: 24px;
}

.rb-checkbox.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.rb-checkbox.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url('../../assets/images/iconscheck.png');
}

.custom-control-input:checked ~ .custom-control-label.fsr::before {
    color: #fff;
    border-color: $rb-fsr;
    background-color: $rb-fsr;
}

.text-fsr {
  color: $rb-fsr;
}

.btn-rb-primary:disabled {
    background: $primary;
    filter: saturate(100%) !important;
    opacity: 0.4;
}