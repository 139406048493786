@import '../../config/constant.scss';
@import '../../config/devices.scss';

.container-login {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

.left-login {
    font-family: $lightFont, sans-serif;
    display: flex;
    flex-direction: column;
    width:385px;
    background-repeat: no-repeat;
    background-size: cover;
    color: $rb-white;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;

    @include respond-below(sm) {
        display: none;
    }

    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30%;
        margin-bottom: 15%;
    }
    .bullets{
        padding: 0 10% 15% 10% !important;
    }

}


.right-login {
    display: flex;
    width:70vw;
    min-height: 100vh;
    flex-direction: column;

    @include respond-below(sm) {
        width:100vw;
    }

    .header-login{
        display: flex;
        width: 100%;
        border: 1px solid $rb-light-grey;

        @include respond-below(sm) {
            align-items: center;
            justify-content: center;
            height: 4.5em;
        }

        .title{
            font-size: 1em;
            font-weight: 300;

            @include respond-below(sm) {
                font-size: 1.5em;
                text-align: center;
            }
        }

        .subtitle{
            width: 100%;
            font-size: 20px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 5%;

            @include respond-below(sm) {
                font-size: 15px;
                text-align: center;
            }
        }
    }

    .login-admin {
        margin: 10% 25% 0% 48px;
        width: 40%;
        @include respond-below(sm) {
            width: initial;
        }
        .title{
            width: 100%;
            font-size: 50px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 5%;

            @include respond-below(sm) {
                font-size: 1.5em;
                text-align: center;
            }
        }
    }

    .login-form{
        display: flex;
        width: 400px;
        margin: 120px 25% 10% 10%;
        @include respond-below(sm) {
            width: 90%;
            margin: 5% 5% 15% 5% !important;
        }

        .title{
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 40px;

            @include respond-below(sm) {
                font-size: 30px;
                text-align: center;
            }
        }

        .login-text{
            height: 50px;
        }

        .forgot-text{
            display: inline-block;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            color: $rb-blue;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            float: right;
            clear: both;
        }

        .line-labels{
            display: flex;
            justify-content: space-between;
        }

        .form-group{
            margin-top: 0px;
            margin-bottom: 20px;
        }

        #login-button{
            margin-top: 0px;
        }
    }

    .footer-login{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0% 25% 0% 10%;
        @include respond-below(sm) {
            margin: 0% 10% 0% 10%;
            justify-content: center;
        }

    }

}
