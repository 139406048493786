@import '../../config/constant.scss';

#dashboard-topbar {
  padding: 0;
  position: fixed;
  top: 59px;
  left: 0;
  z-index: 20;
  .bar {
    &.bar-bottom {
      height: 53px;
    }
    .logo {
      margin-left: 2.5%;
      max-width: fit-content;
      &.mobile {
        margin: 0;
        padding: 0;
        height: 56px;
        display: flex;
        align-items: center;
      }
    }
    span {
      opacity: 0.2;
      margin-right: -4px;
      margin-left: -2px;
    }
    .links {
      cursor: pointer;
      max-width: fit-content;
      padding: 20px 20px;
      svg {
        margin-right: 15px!important;
      }
      &.active {
        // background-color: $rb-yellow;
      }
      &.mobile {
        font-size: 10px;
        padding: 10px 0;
        height: 53px;
        max-width: 100%;
        text-align: center;
        &.active {
          background-color: $rb-yellow;
        }
        svg {
          margin: 0!important;
          margin-bottom: 6px!important;
        }
      }
    }
    .icon {
      max-width: 16px;
    }
    .nickname {
      font-size: 14px;
      max-width: fit-content;
      margin-right: calc(2.5% + 12px);
      text-align: right;
      .arrow-down {
        width: 0; 
        height: 0;
        position: absolute;
        top: 6px;
        right: -12px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $rb-white;
      }
    }
  }
}

#dashboard-header-img {
  height: 150px;
  position: relative;
  text-align: center;
  justify-content: center;
  background-color: #7f8fb1;
  img {
    height: 150px;
    width: auto;
  }
  &.mobile {
    height: 120px;
    width: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
    img {
      height: 120px;
      width: auto;
    }
    .cashback-row {
      width: 100%;
      position: absolute;
      margin: 0;
      text-align: center;
      justify-content: center;
      top: 0;
      left: 0;
      padding-top: 5px;
      .cashback-label {
        color: $rb-yellow;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(20px);
      }
    }
  }
  .cashback-row {
    width: 100%;
    position: absolute;
    margin: 0;
    text-align: center;
    justify-content: center;
    top: 0;
    left: 0;
    padding-top: 5px;
    .cashback-label {
      color: $rb-yellow;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      bottom: 14px;
      left: 50%;
      transform: translateX(20px);
    }
    .header-logo-img {
      max-height: 140px;
      max-width: 500px;
      height: inherit;
    }
  }
}

#cashback-section {
  background-color: #F0F0F2;
}
#dashboard-header {
  height: 110px;
  background: $rb-white;
  &.mobile {
    height: 50px;
  }
  .title {
    padding: 0 112px;
    font-size: 36px;
    font-weight: bolder;
    &.mobile {
      padding: 0 40px;
      padding-top: 20px;
      font-size: 1.25rem;
    }
  }
  .notification {
    cursor: pointer;
    position: absolute;
    right: 0;
    max-width: 112px;
    border-left: 1px solid $rb-lighter-grey;
    height: 20px;
    svg {
      vertical-align: top;
    }
    .badge {
      position: absolute;
      top: -6px;
      transform: translateX(-50%);
    }
  }
}

.notification-list {
  position: absolute;
  z-index: 100;
  right: 20px;
  width: 280px;
  background: #fff;
  top: 50px;
  line-height: 20px;
  font-weight: 400;
  box-shadow: 0 2px 4px 0 $rb-light-shadow;
  font-size: 14px;
  color: $rb-darker-grey;
  border-radius: 4px;
  hr {
    margin: 0 -15px;
    border-color: $rb-lighter-grey;
  }
  .details {
    cursor: pointer;
    color: $rb-yellow;
  }
  .link {
    &:hover {
      background-color: $rb-lighter-grey;
    }
  }
  &:focus {
    outline: none;
  }
}

@media (max-width: 750px) {
  #dashboard-content {
    .distributor-parent-card.card-rb-size {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: 750px) {
  #dashboard-content {
    .distributor-parent-card.card-rb-size {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (min-width: 1000px) {
  #dashboard-content {
    .distributor-parent-card.card-rb-size {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
}

@media (min-width: 1300px) {
  #dashboard-content {
    .distributor-parent-card.card-rb-size {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

#dashboard-content {
  background: #fff;
  .distributor-parent-card{
    .pb-0.text-grey.clickable{
      float: right;
    }
    .font-weight-bolder{
      font-family: $boldFont, sans-serif;
    }
  }
  #locationdropdown-menu, #contractsDropdown-menu{
    .dashed-box-grey svg{
      margin-bottom: 4px;
    }
    .dropdown-item .py-2{
      padding-left: 20px;
    }
  }
  #distributordropdown-menu{
    .dropdown-item .py-2{
      padding-left: 20px;
    }
  }
  .distributor-card {
    height: 415px;
    border: none;
    padding-left: 20px !important;
    padding-right: 20px !important;
    &.mobile {
      height: initial;
      min-height: 415px;
    }
    .py-0{
      padding-top: 2px !important;
    }
  }
  .distributor-logo {
    height: 40px;
    img {
      height: 40px;
      width: auto;
    }
  }
  .add-distributors-list {
    min-height: 312px;
    max-height: 312px;
    overflow-y: auto;
    margin: 0;
    &.mobile {
      min-height: 580px;
      max-height: 580px;
      .add-distributor-card {
        .col {
          padding: 0 8px;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .add-distributor-card {
    height: 120px;
    border-radius: 2px;
    background: $rb-white;
    border: 1px solid $rb-light-grey;
    img {
      display: block;
      max-width:90%;
      max-height:100px;
      width: auto;
      height: auto;
    }
    .selector {
      padding-left: 30px;
      .checkmark {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        position: absolute;
        top: 10px;
        &:after {
          left: 7px;
          top: 4px;
        }
      }
    }
  }
  .oval {
    max-width: 32px;
    margin-left: 15px;
    padding: 0;
    .row {
      height: 32px;
      border: 1px solid $rb-blue;
      border-radius: 100%;
    }
  }
  .rebates-approbal-check {
    .checkbox-container {
      margin: 0;
      input:checked ~ .checkmark {
        background-color: $rb-green;
        border-color: $rb-green;
      }
      .checkmark {
        right: 0;
        left: initial;
      }
    }
  }
}

#activateDistributorModal {
  .check {
    background: $rb-green;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    padding-top: 3px;
    margin-bottom: 7px;
    &.all {
      margin-bottom: 0;
    }
    &.mobile {
      margin: 0;
    }
  }
}

.see-details-partners{
  color: white;
  background: $rb-yellow;
  width: 80% !important;
  height: 100% !important;
}

.see-details-partners:hover{
  color: white;
}
.see-details-partners:visited{
  color: white;
}


#dashboard-settings {
  background-color: $rb-white;
  border-radius: 1em;
  margin: 1%;
  padding: 2%;
  .tab-content {
    width: 100%;
  }
}

.programs-load-more {
  color: $rb-blue;
  font-family: $bodyFont;
  font-size: 1em;
  font-weight: 400;
  text-align: left;
}

.owl-carousel .owl-item img {
  display: block;
  width: 60% !important;
  margin-left: auto;
  margin-right: auto;
}

.bound-left {
  border-left: solid 1px $rb-dark-aqua;
  border-image: linear-gradient(to bottom, rgba(0,0,0,0) 25%,$rb-dark-aqua 25%,$rb-dark-aqua 75%,rgba(0,0,0,0) 75%);
  border-image-slice: 1;
}

.bound-right{
  border-right: solid 1px $rb-dark-aqua;
}

#dashboard-footer {
  background-color: $rb-dark-blue;
  color: $rb-white;
  min-height: 100px;
  flex-flow: column;
  .bar {
    justify-content: space-between !important;
    width: 90%;
  }
  .bar:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .logo {
    height: 60px;
    width: auto;
    &.mobile {
      height: 40px;
    }
  }
  .copyright {
    font-size: 15px;
    &.mobile {
      font-size: 12px;
    }
  }
}

.no-p {
  padding: 0 !important;
  margin: 0 !important;
}

.card-container {
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  display: flex;
  border-collapse: separate;
}


.program-card {
  flex: 0 0 80%;
  border-collapse: separate;
  .see-details {
    visibility: hidden;
  }
}

.program-card:hover {
  box-shadow: 0 1px 1px 0 $rb-light-shadow;
  .see-details {
    visibility: visible;
  }
}

.termsConditions{
  color: $rb-darker-grey;
  font-family: $bodyFont;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 0.9em;
  text-align: left;
}

.termsConditionsBlue{
  color: $primary;
}

#pickDateModal {
  .modal-content {
    background: none;
    left: 50%;
    transform: translateX(-50%);
    width: initial;
    .modal-body {
      padding: 0;
    }
  }
}

.customDatePickerAlign, 
.customDatePickerAlign > div > div.react-datepicker__portal {
   align-items: normal;
}

.modal-news-desktop {
  background-image: url('../../assets/images/da-cashback.png');
  background-repeat: no-repeat;
  background-position: center; 
  height: 420px;
  width: 100%;
}

.modal-news-mobile {
  background-image: url('../../assets/images/da-cashback.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 100%;
}

.modal-news-tablet {
  background-image: url('../../assets/images/da-cashback.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  height: 500px;
  width: 100%;
}

.modal-news-text {
    color: $rb-white;
    font-weight: 400;
    line-height: 43px;
}

.modal-news-text2 {
  color: $rb-white;
  font-weight: 400;
  line-height: 24px;
}

.modal-news-line-bottom {
  border: solid $rb-yellow;
  border-width: 0 0 2px 0;
  opacity: 1;
}

.modal-reports-map {
  background-image: url('../../assets/images/truck.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  height: 400px;
  width: 100%;
}

.modal-reports-title {
  color:$rb-yellow;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
}

.modal-reports-subtitle {
  color: $rb-white;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
}

.modal-reports-text {
  color: $rb-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.top-spacer-150{
  margin-top:150px;
}
.top-spacer-200{
  margin-top:200px;
}

.btn-red-online {
  color: $rb-red-Light !important;
  border: 1px solid $rb-red-Light;
}

.darkBlue {
  background-color: $rb-dark-blue;
}

.greyHome {
  background-color: $rb-grey-home;
}

.food-cost-sidebar-overlay {
  z-index: 2000;
}

.food-cost-sidebar .slide-pane__content{
  background-color: $primary;
}