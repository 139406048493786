@import '../../config/constant.scss';
@import '../../config/devices.scss';


.container-signup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.left-signup {
  font-family: $lightFont, sans-serif;
  display: flex;
  flex-direction: column;
  width:385px;
  height: 200vh;
  background: url('../../assets/images/bg-image-2.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: $rb-white;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  &.be {
    background: url('../../assets/images/be-image-sidebar.png');
  }
  &.simple {
    background: url('../../assets/images/simple-signup-sidebar.png');
    height: inherit;
    min-height: 100vh;
    &.fsr-bg {
      background: url('../../assets/images/food-service-rewards-sidebar.png');
    }
  }
  @include respond-below(sm) {
      display: none;
  }

  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30%;
    margin-bottom: 15%;
    flex-direction: column;
  }
  .bullets{
    padding: 0 10% 15% 10% !important;

    .line-items{
      align-items: unset;
    }

    .bullet-circle-container{
      top: 0px;
    }
  }
}

.right-signup {    
  display: flex;
  width:70vw;  
  flex-direction: column;

  @include respond-below(sm) {      
      width:100vw;
  }

  .header-main{      
      width: 100%;        
      border: 1px solid $rb-light-grey;
      background-color: $rb-white; 
      position:fixed;
      z-index:3;

      .header-fixed{        
        display: flex;
        
        @include respond-below(sm) {        
            align-items: center;
            justify-content: center;
            height: 4.5em;
        }

        .title{
            font-size: 1em;
            font-weight: 300;

            @include respond-below(sm) {
                font-size: 1.5em;
                text-align: center;
            }
        }
      }      
  }

  .header-signup{
    display: flex;
    width: 80%;
    margin: 120px 0% 0% 7%;
    flex-direction: column;
    
    @include respond-below(sm) {
      width: 100%;
      margin: 20% 0% 0% 0%;
    }

    .title{
      width: 100%;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 24px;
      padding-left: 3%;

      @include respond-below(sm) {
          margin: 2% 0% 5% 0%;
          padding-left: 0%;
          font-size: 30px;
          text-align: center;
      }
    }

    .subtitle{
      color: #202020;
      line-height: 24px;
      margin-top: 16px;
    }

    .steps{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      max-width: 95%;
      margin: 24px 0 24px 3%;

      .dash{
        margin-left: 20px;
        margin-right: 20px;
      }

      @include respond-below(sm) {
        max-width: 100%;
        padding: 0% 10% 0% 10%;
        padding-left: 10%;
        justify-content: space-between;
      }
    }
  }

 .welcome-signup button{
    margin-bottom: 24px;
    min-width: 400px;
  }

  .signup-form{
    //display: flex;
    width: 80%;
    margin: 16px 0% 0% 7%;

    @include respond-below(sm) {
        width: 100%;
        margin: 10% 0% 10% 0%;
    }

    button {
      letter-spacing: 0px;
    }

    .title{
        font-family: $boldFont, sans-serif;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 2%; 
        text-align: left;
        padding-left: 2%;

        @include respond-below(sm) {
          padding-left: 5%;
            
        }
    }

    .line-form{
      display: flex;
      flex-direction: row;
      padding-left: 2%;
      
      @include respond-below(sm) {
        flex-direction: column;
        padding-left: 5%;
      }

      .rb-input{
        min-width: 400px;
      }

      .rb-input::placeholder{
        color: #202020;
        opacity: 50%;
      }

      .rb-select{
        min-width: 400px;
      }

      .rb-select::placeholder{
        color: #202020;
        opacity: 50%;
      }

      .rb-dropdown{
        min-width: 400px;
      }

      .dropdown-menu{
        min-width: 400px;
      }

      .rb-dropdown::placeholder{
        color: #202020;
        opacity: 50%;
      }

      .check{
        display: flex;
        flex-direction: row;
        align-items: center;
        
        @include respond-below(sm) {
          flex-direction: row-reverse;          
        }
        .checked-text{
          margin-left: -3%;
        }
      }

    }
   

    .line-group{
      display: flex;
      flex-direction: row;
      padding-left: 2%;
      
      @include respond-below(sm) {
        flex-wrap: wrap;     
        flex: 100% 0;
        padding-left: 5%;
      }      
    }

    .form-box{
      max-width: 60%;
      margin: 0%;
      @include respond-below(sm) {
        max-width: 100%;
      }

      .line-box{
        display: flex;     
        align-items: center;
        height: 60px;
        margin: 2%;
        font-size: 14px;
  
        @include respond-below(sm) {
                
        }
      }
    }    

  }
}

.signup-info-form{
  .header-signup {
    margin-top: 60px;

    @include respond-below(sm) {
      margin-top: 75px;
    }
  }

  .rb-input{
    min-width: 0px !important;
  }

  .rb-select{
    min-width: 0px !important;
  }

  .rb-dropdown{
    min-width: 0px !important;
  }

  .signup-form.step-1{
    .btn-rb-primary {
      margin-top: 24px;
    }
  }

  .signup-form.step-3{
    .line-form:last-of-type{
      margin-top: 24px;
    }
  }

  .signup-form.step-4{
    .submit-buttons{
      margin-top: 24px;
    }
  }

}

.solid-box-grey {
  border: 1px solid $rb-light-grey;
  color: $rb-darker-grey;
}

.signup-header-sucessfully {
  max-width: 100%;
  margin: 10% 0% 0% 45%;
  text-align: center;

  @include respond-below(sm) {
    
  }
}

.signup-title-sucessfully {
  margin: 5% 0% 0% 1%;
  color: $rb-black;
  font-family: $bodyFont;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;

  @include respond-below(sm) {
    max-width: 100%;
    margin: 2% 0% 0% 3%;
    text-align: center;
    font-size: 30px;
  }
}

.signup-body-words {
  margin: 2% 0% 0% 2%;
  color: $rb-black;
  font-family: $bodyFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  @include respond-below(sm) {
    max-width: 100%;
    margin: 3%;
    text-align: center;
    
  }
}

.react-datepicker-wrapper input {
  border-radius: 2px;
  line-height: 28px;
  font-size: 14px;
  text-align: left;
  background: $rb-white;
  border: 1px solid $rb-light-grey;
  color: $rb-black;
  height: 40px;
  padding-left: 0.8em
}

.signup-signed {
  font-family: BrushScript, BrushScript2, 'Brush Script MT';
  font-size: 1.9em;
}

.signup-authorized{
  color: $rb-black;
  font-family: $bodyFont;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  &-div {
    color: $rb-black;
    font-family: $bodyFont;
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    border: 1px solid rgb(169, 169, 169);
    padding: 5px 15px;
    max-height: 150px;
    overflow: auto;
  }
}

.tooltip-inner {
    font-family: $bodyFont, sans-serif;
    line-height: 16px;
    background-color: $rb-white;
    color: $rb-black;
    border: 0.5px solid $rb-black;
}

.tooltip.bs-tooltip-bottom .tooltip-inner {
  background:#fff !important;
}

.tooltip .arrow:before {
  border-bottom-color:#fff !important;
  border-top-color:#fff !important;
}

.tooltip.bs-tooltip-right .arrow:before {
    border: 0px;
}
.tooltip.bs-tooltip-left .arrow:before {
    border: 0px ;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border: 0px ;
}
.tooltip.bs-tooltip-top .arrow:before {
    border: 0px ;
}

.onboarding-on {
  width: 8px;
  height: 8px;
  background-color: $rb-blue;
  border-radius: 100%;
}

.onboarding-off {
  width: 8px;
  height: 8px;
  background-color: $rb-light-grey;
  border-radius: 100%;
}

.transition-left {
  left: 0;
  transition: left 1s;
}

.signup-simple-register-text {
  text-transform: uppercase;
  // color: $rb-yellow;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px ;
}

.top-0.custom-checkbox .custom-control-label::after {
  top: 0;
}

.top-0.custom-checkbox .custom-control-label::before {
  top: 0;
}

.check-creds {
  background: $rb-green;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  padding-top: 3px;
  margin-bottom: 7px;
  &.all {
    margin-bottom: 0;
  }
  &.mobile {
    margin: 0;
  }
}